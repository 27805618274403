import defaultContentService from '../Services/defaultContentService'

const defaultStyles = {
    loaded: true,
    defaultColor: '#00FF00',
}

export const getDefaultSettings = () => {
    return async dispatch => {
        const defaultStyles = await defaultContentService.getDefaultStyles()
        dispatch(
            {type: 'INIT_DEFAULT_STYLES', data: defaultStyles}
        )
    }
}

const reducer = (state = defaultStyles, action) => {
    switch (action.type) {
        case 'INIT_DEFAULT_STYLES':
            return action.data
        default: return state
    }
}

export default reducer