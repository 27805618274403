import './Comment.css'

const Comment = (props) => {
    let styles = 'comment-container flex '

    if (props.className) {
        styles += props.className
    }

    return (
        <div className={styles}>
            <div className='bar' />
            <div className='comment-text'>{props.comment}</div>
            <div className='commentator-row-container flex'>
                <div className='bar width-30' />
                <div className='commentator'>{props.commentator}</div>
                <div className='bar width-30' />
            </div>
        </div>
    )
}

export default Comment