import { createStore, combineReducers, applyMiddleware } from 'redux'

import defaultStyleReducer from './Reducers/defaultStyleReducer'
import textContentReducer from './Reducers/textContentReducer'

import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

const reducer = combineReducers({
    defaultStyle: defaultStyleReducer,
    defaultContent: textContentReducer
})

const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)

export default store