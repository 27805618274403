import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'
import { useSpring, animated } from 'react-spring'
//import { useEffect} from 'react'

import Form from '../Components/Form/Form'
import LinkBox from '../../Client/Components/Link/LinkBox'
import Menu from '../Menu/Menu/Menu'
import CommentContainer from '../../Client/Components/Comment/CommentContainer'

import image from '../../../Dev-Aineistot/sammal.jpg'
import logo from '../../../Dev-Aineistot/v5_4_turkoosi.png'
import heart from '../../../Dev-Aineistot/syddän.png'
import kaari from '../../../Dev-Aineistot/kaariSVG.svg'
import lock from '../../../Dev-Aineistot/lukko2.png'
import float from '../../../Dev-Aineistot/float.png'

import heartRed from '../../Client/Elements/heart_red.png'
import lora from '../../../Dev-Aineistot/lora1.jpg'
import lora2 from '../../../Dev-Aineistot/lora2.jpg'
import lora3 from '../../../Dev-Aineistot/lora3.jpg'
import kukkaBr from '../../../Dev-Aineistot/kukka-br-web.png'

import './FrontPage.css'
import Badge from '../../Client/Components/Badge/Badge'
import ElementOne from '../../Client/Elements/ElementOne'
import ElementThree from '../../Client/Elements/ElementThree'
import Dancer from '../../Client/Elements/Dancer'
import WavyLine from '../../Client/Elements/WavyLine'
import maisema from '../../Client/Elements/maisema.jpg'
import threeDancers from '../../Client/Elements/three_dancers_logo.png'

const FrontPage = () => {
    const defaultStyle = useSelector(state => state.defaultStyle)
    const defaultContent = useSelector(state => state.defaultContent)
    
    const [elementOneVisibility, setElementOneVisibility] = useState(false)
    const [elementTwoVisibility, setElementTwoVisibility] = useState(false)
    const [elementThreeVisibility, setElementThreeVisibility] = useState(false)

    //mobile response ------------
    const [logoPosition, setLogoPosition] = useState(null)
    const [logoSize, setLogoSize] = useState(null)

    //mobile response ------------

    const [hovered, setHovered] = useState(false)

    const ref = useRef(null)

    //TODO: taustakuva lazyloadingilla  
    //IMG:t SVG:ksi

    const [lockHovered, setLockHovered] = useState(false)

    useEffect(() => {
        setLogoPosition(window.innerHeight / 5)
        setLogoSize(20)

        if (window.innerWidth < 700) {
            setLogoPosition(window.innerHeight / 8)
            setLogoSize(18)
        }

        if (window.innerWidth < 500) {
            setLogoPosition(window.innerHeight / 8)
            setLogoSize(18)
        }
    }, [])

    useEffect(() => {
        if (!lockHovered) return

        const timeout = setTimeout(() => {
            setLockHovered(false)
        }, 200)

        return (() => {
            clearTimeout(timeout)
        })
    }, [lockHovered])


    const visibilityChangeOne = (isvisible) => {
        if (isvisible) {
            setElementOneVisibility(true)
        }
    }

    const visibilityChangeTwo = (isvisible) => {
        if (isvisible) {
            setElementTwoVisibility(true)
        }
    }

    const visibilityChangeThree = (isvisible) => {
        if (isvisible) {
            setElementThreeVisibility(true)
        }
    }

    const defaultBackground = {
        backgroundColor: defaultStyle.defaultColor,
        width: '100%',
        height: '100vh'
    }

    const links = [
        {header: 'Blogi', description: 'Tähän joku blogin esittelyteksti?', enabled: true},
        {header: 'Kurssit', description: 'Tervetuloa kursseille ja tapahtumiin netissä ja paikan päällä', enabled: true},
        {header: 'Fysioterapia', description: 'Yksilöllistä ohjausta sinun tarpeisiin', enabled: true},
        {header: 'Kirjeet', description: 'Rakkauskirjeitä, ohjausta ja kuulumisia minulta sinulle', enabled: true},
        {header: 'Kuvat', description: 'Tunnelmia ja inspiraatiota omalle matkallesi', enabled: true},
    ]

    const sectionFiveInserts = [
        'vapauttaa häpeästä ja pelosta',
        'herättää elämään',
        'vahvistaa intuitiota',
        'luo sisäisen rauhan',
        'antaa rohkeutta elää oman näköistä elämää',
        'mahdollistaa syvemmän yhteyden toisiin ihmisiin ja ympäristöön',
        'lisää onnellisuutta'
    ]

    //TODO
    //lisää animated -> react-komponentti tarvii jonkin oman systeeminsä
    const elementAnimation = useSpring(
        {
            transform: hovered ? 'translateY(40px)' : 'translateY(0px)',
            config: {
                tension: 300,
                friction: 10
            }
        }
    )

    const lockTextAnimation = useSpring(
        {
            transform: lockHovered ? 'scale(1.1)' : 'scale(1)',
            config: {
                tension: 300,
                friction: 10
            }
        }
    )

    const lockAnimation = useSpring(
        {
            transform: lockHovered ? 'translateY(-10px)' : 'translateY(0px)',
            config: {
                tension: 300,
                friction: 10,
            }
        }
    )

    const elementOneAnimation = useSpring(
        {
            transform: elementOneVisibility ? 'translateX(0px)' : 'translateX(-20em)',
            config: {
                friction: 50,
            }
        }
    )

    const elementTwoAnimation = useSpring(
        {
            transform: elementTwoVisibility ? 'translateX(0px)' : 'translateX(-20em)',
            config: {
                friction: 50,
            }
        }
    )

    const elementThreeAnimation = useSpring(
        {
            transform: elementThreeVisibility ? 'translateX(0px)' : 'translateX(20em)',
            config: {
                friction: 50,
            }
        }
    )

    return (
        <div ref={ref} style={{overflow: 'hidden'}}>
            {/*<Menu />*/}
            {/* Cover */}
            <div>
                <div className='flex-column' style={{top: `${logoPosition}px`}}>
                    <img style={{height: `${logoSize}em`}} src={logo} alt='Elävä tila logo'/>
                    <div className='slogan'>
                        <div>{defaultContent.slogan[0]}</div>
                        <img className='heart' src={heart} alt=''/>
                        <div>{defaultContent.slogan[1]}</div>
                        <img className='heart' src={heart} alt=''/>
                        <div>{defaultContent.slogan[2]}</div>
                    </div>
                </div>

                <img className='kaari' src={kaari} alt=''/>
                <animated.img
                    onMouseEnter={() => setLockHovered(true)} 
                    style={lockAnimation} className='lock' src={lock} alt=''/>
                <animated.div
                    onMouseEnter={() => setLockHovered(true)}  
                    style={lockTextAnimation} className='fp-call-for-action'>Avaa itsesi</animated.div>
                
                <img className='front-bg-image' src={image} alt=''/>
            </div>

            <div>
                <div className='intro-image-row'>
                   {window.innerWidth > 1100 ? <div><img className='kukka-br' src={kukkaBr} alt=''/></div> : null}
                    <div className='intro-image'>
                        <img className='lora' src={lora} alt='Lora Andersson'/>
                        
                        <div className='lora-name'>
                            <img className='heart lora-heart' src={heartRed} alt=''/>
                            <div className='name'>Lora</div>
                        </div>
                    </div>
                    {window.innerWidth > 1100 ? <div><img className='kukka-br reverse' src={kukkaBr} alt=''/></div> : null}
                </div>
            </div>
            
            {/*First section */}

            <div className='section-1-container'>

                <ElementOne color='#FAA41C' opacity='0.20' classNames='element-one-size'/>
                
                <div className='flex text-over-element'>
                    <animated.div style={elementOneAnimation} className='quote'>
                        Haluaisitko tuntea yhteyden sisimpääsi?<br />
                        Rohkeutta hyväksyä sen,
                        kuka todella olet?<br />
                        Elää <span className='highlight-yellow'>vapaudessa</span> ja rakkaudessa ehdoitta?
                    </animated.div>
                    {/*TODO: line spacet ja highlightit käyttäjän generoimina*/}
                    <VisibilitySensor onChange={visibilityChangeOne}>
                        <div className='general-text section-text-size'>
                            Se on mahdollista.<br /><br />
                            Itsensä äärelle pysähtyminen ja
                            kiitollisuus ovat tärkeimpiä työkaluja
                            matkalla onnellisuuteen.<br /><br />
                            Jätä yhteystietosi niin lähetän sinulle
                            {/* highlight buttoniksi, joka avaa pop upin */}
                            <a className='highlight-yellow no-underline' href="mailto:elavatila@gmail.com"> ilmaisen meditaation,</a> jonka avulla voit
                            harjoitella pysähtymistä itsesi äärelle ja
                            kokea sisäistä rauhaa ja keveyttä.<br /><br />
                            Matkasi alkaa tästä.
                        </div>
                    </VisibilitySensor>
                </div>
            </div>

            {/*1st call for action */}

            {/*<div className='cfa-container flex'>
                <Form 
                    url= 'osoite-tähän' //TODO
                    fields= {[
                        {name: 'Nimi', type: 'text', value: ''},
                        {name: 'Sähköposti', type: 'email', value: ''},
                    ]}
                    button= 'Kyllä, kiitos!'
                    buttonClickMessage= 'Tilattu! Tarkista sähköpostisi!'
                    buttonStyles='button'
                    inputStyles='input'
                />
                <div className='join-mailinglist'>
                    {TODO: line spacet ja highlightit käyttäjän generoimina} 
                    Tilaa ilmainen meditaatiosi sähköpostiisi,
                    ja liityt samalla Elävän tilan
                    sähköpostilistalle, jonne jaan
                    säännöllisesti vinkkejä, voimaa ja
                    ajatuksia.<br /><br />
                    [Ehkä jokin GDPR-klausuuli pitää olla
                    tässä..]
                </div>
                <Badge 
                    title='Ilmainen meditaatio'
                    HorizontalPosition='center'
                    additionalStyles='badge'
                />
            </div> */}

            {/* introduction */}
            <div className='flex introduction'>
                <div className='flex intro-text-container'>
                    <div className='intro-header flex'>
                        <div className='intro-header-hi'>Hei,</div>
                        <div className='intro-header-name'>Olen <span className='highlight-yellow'>Lora!</span></div>
                    </div>
                    <div className='intro-text'>
                        Olen koulutukseltani psykofyysinen
                        fysioterapeutti, tanssija ja kokonaisvaltainen
                        rentoutusohjaaja. Minulla on lähes
                        vuosikymmenen kokemus terapiatyöstä,
                        jossa olen auttanut mm. kivusta,
                        ahdistuksesta, jännittyneisyydestä, stressistä,
                        hengitysvaikeuksista, syömishäiriöistä ja
                        ruumiinkuvan häiriöistä sekä henkistä ja
                        ruumiillista väkivaltaa kokeneita asiakkaita.
                    </div>    
                </div>
                <img className='lora2' src={lora2} alt='Lora'/>
            </div>

        {/* Second Section */}
        
        <div className='section-1-container section-2-container-size'>

            <ElementOne classNames='element-2 element-one-size' color='#85459A' opacity='0.20'/>
            
            <div className='flex text-over-element text-over-element-element-two'>
                <animated.div style={elementTwoAnimation} className='quote'>
                Tärkeää on ihmisten
                kohtaaminen tässä hetkessä
                tasavertaisina yksilöinä
                ja ymmärrys siitä, että
                <span className='highlight-purple'> jokainen, sinäkin</span> olet tehnyt parhaasi.
                </animated.div>
                {/*TODO: line spacet ja highlightit käyttäjän generoimina*/}
                <VisibilitySensor onChange={visibilityChangeTwo}>
                    <div className='general-text section-2-spacing'>
                    Tärkeimpiä käyttämiäni työkaluja ovat
                    kehotietoisuus ja kehollisuus, tanssi,
                    liike, kosketus, erilaiset ahdistuksen (ja
                    stressin)-hallintakeinot, rentoutuminen,
                    hengitys, meditaatio, luontoyhteys
                    sekä antautuminen tähän hetkeen ja
                    kannatelluksi tuleminen. Yksi tärkeistä
                    ohjauskeinoistani on myös keskustelu.
                    </div>
                </VisibilitySensor>
            </div>
        </div>

        {/* Third Section */}

        <div className='container-3'>
            <img className='element-3' src={float} alt=''/>

            <div className='cont-3-text-cont flex'>
                <img className='lora-3' src={lora3} alt='Lora'/>
                <div className='intro-text cont-3-text' style={{display: 'flex', alignItems: 'center', lineHeight: '1.8em'}}>
                    Oman elämäni ja menetysten kautta olen
                    oppinut luottamaan elämään ja omaan sisäiseen
                    viisauteeni. Kun aloin huolehtia itsestäni ja
                    rakastaa itseäni enemmän, syventyivät suhteeni
                    myös lapsiini, ystäviin, luontoon, arkeen, työhön
                    ja vapaa-aikaan. Luon ympärilleni kauneutta ja
                    olen kiitollisempi. Vedän puoleeni uusia ihmisiä
                    ja kokemuksia, joita en aiemmin uskaltanut edes
                    kaivata. Tunne olevani kauniimpi, vapaampi ja
                    yksinkertaisesti onnellisempi.<br /><br />
                    Tiedän kuka olen ja voin valita, millaista elämää
                    elän.
                </div>
            </div>
        </div>

        {/* Link Section */}
        {/*<div className='link-container flex'>
            {links.map((link, index) => 
                <LinkBox
                    key={`link-${index}`}
                    header={link.header} 
                    enabled={link.enabled}
                >
                    {link.description}
                </LinkBox>
            )}
        </div>*/}

        {/* Fourth Section */}
        <div className='section-1-container section-3-container'>
            <div className='element-container'>
                <ElementThree 
                        classNames='element-2' color='#2BB673' opacity='0.20'/>
            </div>
                
            <div className='flex text-over-element reverse section-3-mobile'>
                <animated.div style={elementThreeAnimation} className='quote quote-extra-spacing section-3-quote'>
                    Jokainen
                    <span className='highlight-green'> rakkaudenteko,</span> jonka suot itsellesi
                    luo kauneutta, iloa ja
                    onnellisuutta ympärillesi.
                </animated.div>
                {/*TODO: line spacet ja highlightit käyttäjän generoimina*/}
                <VisibilitySensor onChange={visibilityChangeThree}>
                    <div className='general-text section-2-spacing alt-padding section-four-text'>
                        Haluan tarjota sinulle mahdollisuuden muuttaa
                        suuntaa ja halutessasi vaikka koko elämäsi, jos
                        niin toivot. Haluan antaa sinulle toivoa, valoa ja
                        uskoa tulevaan.<br /> <br /> 
                        Kun tunnet itsesi, voit valita ympärillesi asioita,
                        jotka lisäävät hyvinvointiasi. Silloin osaat myös
                        viestiä toiveistasi muille ja valita mihin käytät
                        energiasi - rakkauteen vai taisteluun.
                        Kun olet kosketuksissa sisimpääsi, et enää
                        tarvitse muiden hyväksyntää vaan olet vapaa
                        ilmaisemaan kaikki puolesi ja elämään oman
                        näköistä elämää.<br /> <br /> 
                        Kun rakastat itseäsi, sinulla on paljon
                        annettavaa myös läheisillesi, harrastuksille,
                        työlle, maailmalle.
                    </div>
                </VisibilitySensor>
            </div>
        </div>

        {/* Fifth Section */}
<div style={{position: 'relative', top: '-9em'}}>{/*fixes additional space afte sec 5 due to relative top -12em */}
        <div className='container-5'>
                <div className='section-5-header'>
                    <div>Muista että</div>
                    <div>Itsensä rakastamisen taito...</div>
                </div>
                <div className='section-5-text'>
                    {sectionFiveInserts.map((insert, i) => <div key={`insert-${i}`}>{insert}</div>)}
                </div>
                
        </div>

        {/*2nd call for action */}

        {/*<div className='cfa-container cfa-2 flex'>
            <div className='cfa-number-two flex-column-2'>
                <WavyLine />
                <div className='cfa-2-text'>
                    Ilmainen meditaatio
                    pysähtymiseen ja
                    kiitollisuuteen,
                    ole hyvä!
                </div>
                <WavyLine />
            </div>

            <div className='cfa-logo-container flex'>
                < Dancer />
            </div>

            <Form
                url= 'osoite-tähän' //TODO
                //TODO syncciin toisen kanssa
                fields= {[
                    {name: 'Nimi', type: 'text', value: ''},
                    {name: 'Sähköposti', type: 'email', value: ''},
                ]}
                button= 'Kyllä, kiitos!'
                buttonClickMessage= 'Tilattu! Tarkista sähköpostisi!'
                buttonStyles='button button-yellow'
                inputStyles='input'
                additionalStyles='vertical-center'
            />
        </div>}

        {/* Comments Section */}
        <CommentContainer />
    </div>

{/*fixes additional space afte sec 5 due to relative top -12em */}

    {/* Footer */}
    <footer>
            <div className='logo-alt'>
                <img className='three-dancers-logo' src={threeDancers} alt='logo'/>
                <div className='logo-alt-text'>Sinun on tarkoitus elää!</div>

            </div>
            <img className='scenery' src={maisema} alt='Järvimaisema, jossa etualalla kallioita ja takana kohoaa kuusimetsää.'/>   

            <div className='company-details'>
                {/*LINKKI GDPR*/}<br />
                Elävä tila - Living Alive<br />
                Lora Andersson <br />
                elavatila@gmail.com <br />
                Y-tunnus 2900888-2 <br />

                <div>
                    <a className='highlight-yellow no-underline' href='https://www.instagram.com/elava.tila/' target='_blank' rel="noreferrer">Instagram</a>
                </div>
                <div>
                    <a className='highlight-yellow no-underline' href='https://www.facebook.com/elavatila/' target='_blank' rel="noreferrer">Facebook</a>
                </div>

                © {new Date().getFullYear()} Elävä Tila.
            </div>
        </footer>
</div>


    )
}

export default FrontPage