import Comment from './Comment'
import moon from '../../Elements/moon.png'

import {nanoid} from 'nanoid'
import './Comment.css'

const CommentContainer = () => {
    const comments = [
        {type: 'other'},
        {type: 'comment', commentator: 'Irmeli, 60', comment: 'Lora näkee ihmisen kokonaisuutena - ei oireena tai diagnoosina. Lorasta välittyy rakkaudellista energiaa ja valtava määrä ammatillista erityisosaamista. Suosittelen lämpimästi!'},
        {type: 'comment', commentator: 'Annika, 31', comment: 'Sydämeni pohjasta kiitos, kiitos, KIITOS! <3 Olet ollut ihan mielettömän suureksi avuksi omalla toipumis- ja kuntoutusmatkallani sekä rentoutumisen apuna. Eilinen kehollinen rentoutus oli myös todella hieno kokemus, itku tuli kun kotiin pääsin kiitollisuudesta.'},
        {type: 'comment', commentator: 'Eveliina, 39', comment: 'Lora ottaa taitavasti huomioon ihmisen henkisen ja fyysisen kokonaisuuden, ja on aidosti läsnä ohjaustilanteessa. Olen hyvin kiitollinen ohjauksesta, sain paljon työkaluja itseni rauhoittamiseen ja huolenpitoon sekä rentoutumiseen niin henkisesti kuin fyysisestikin.'},
    ]

    const wideComment = {type: 'comment', commentator: 'P.P. 44', comment: 'Loran tuella löysin kehoni uudelleen. Lora kohtasi minut sairauskohtauksen jälkisyövereissä. Hän kuunteli minua ja tuki löytämään yhteyden omaan kehooni uudelleen. Vankalla läsnäolon taidollaan ja idearikkaudellaan hän arvioi kulloisenkin tarpeeni kuntoutumisen polulla. Puhuminen, kuunteleminen ja tekeminen vuorottelivat luontevasti. Loran työtapa on kekseliäs ja voimaannuttava, hauska sekä koko ajan asiakasta kuunteleva. Kuntoutus loppui jokin aika sitten, mutta sen opit kantavat minua. Entisestä suorittajasta on kuoriutunut itsensä kuuntelija, joka palaa muistuttamaan itseään Lora opeista hermostuessaan esimerkiksi työpaineista. Voin lämpimästi suositella Loran palveluja sinulle, joka hyötyisit itsesi kohtaamisesta arvostavasti liikkumisen iloon yhdistettynä.'}
    
    return (
        <div className='comments-list'>
            {comments.map((item) => {
                if (item.type === 'other') {
                    return <div className='moon-container' key={nanoid()}>
                            <img className='moon-logo' src={moon} alt='moon logo' />
                            <div className='moon-header'>Palautteita:</div>
                        </div>
                }

                return <Comment key={nanoid()} className='comment-list-item' comment={item.comment} commentator={item.commentator}/>
            })}
            <Comment className='comment-list-item-wide' comment={wideComment.comment} commentator={wideComment.commentator}/>
        </div>
    )
}

export default CommentContainer