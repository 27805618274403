import { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import './App.css';

//vakiomoduulit
import FrontPage from './Layouts/Constants/Frontpage/FrontPage'
import AdminPanel from './Layouts/Constants/AdminPanel/AdminPanel'
import ReturnLater from './Layouts/Constants/ReturnLater/ReturnLater'

//asiakaskohtaiset moduulit

const App = () => {
  const [billsPaid, setBillsPaid] = useState(true)

  useEffect(() => {
    //TODO hae palvelimelta ja firestoresta sisältö
    //TODO Jos laskut maksamatta, sisältö palauttaa false, jolloin
    //TODO hae localStoragesta kirjautumistiedot jos on (admin)
  }, [])

  useEffect(() => {
    //TODO hae localStoragesta kirjautumistiedot jos on (videopalvelun asiakasasiakas)
  }, [])

  const routes = [
    //TODO MUUT PATHIT
    {path: '/admin', Component: AdminPanel}, //vakio
    {path: '/', Component: FrontPage}, //vakio
  ]
  //TODO: 404 path

  if (!billsPaid) return <ReturnLater />

  return (
    <div >

      {routes.map(({path, Component}) => 
        <Route key={path} exact path={path}>
          <Component />
        </Route>
      )}
      
    </div>
  );
}

export default App;
