import defaultContentService from '../Services/defaultContentService'

const defaultContent = {
    loaded: true,
    slogan: [
        'Vapaus',
        'Rakkaus',
        'Luonnollisuus'
    ],
}

export const getDefaultSettings = () => {
    return async dispatch => {
        const defaultContent = await defaultContentService.getDefaultContent()
        dispatch(
            {type: 'INIT_DEFAULT_CONTENT', data: defaultContent}
        )
    }
}

const reducer = (state = defaultContent, action) => {
    switch (action.type) {
        case 'INIT_DEFAULT_CONTENT':
            return action.data
        default: return state
    }
}

export default reducer